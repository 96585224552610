.cookies_wrapper-back {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    // background-color: rgba(51, 51, 51, 0.80);
    z-index: 99999;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;

    .cookies_banner_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        border-radius: 1rem;
        border: 1px solid var(--900);
        padding: 1rem;
    }

    .cookies_info_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        span {
            text-align: center;
            font-size: 1.2rem;
        }
        .know_cookies_buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-top: .65rem;
            gap: 1rem;


            .cookies_policy {
                padding: .3rem 1rem;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;

                background-color: transparent;
                border-radius: .5rem;
                color: #333;
                border: 1px solid #333;
                cursor: pointer;

                &:active {
                    background-color: #c9ccd5;
                    border: 1px solid #c9ccd5;
                }

                &.readMore{
                    background-color: #c9ccd5;
                    border: 1px solid #c9ccd5;
                }

                span {
                    font-size: .85rem;
                }
            }

            .cookies_buttons_wrapper {
                margin-left: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;

                .dismiss_cookies {
                    padding: .3rem 1rem;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                    background-color: #b3a077;
                    border-radius: .5rem;
                    color: #fff;
                    border: 1px solid #b3a077;
                    cursor: pointer;

                    &:active {
                        background-color: #c9ccd5;
                        border: 1px solid #c9ccd5;
                    }

                    span {
                        font-size: 1rem;
                    }
                }

                .allow_cookies {
                    padding: .3rem 1rem;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                    background-color: var(--700);
                    border-radius: .5rem;
                    color: #fff;
                    border: 1px solid #333;
                    cursor: pointer;

                    &:active {
                        background-color: #c9ccd5;
                        border: 1px solid #c9ccd5;
                        color: #333;
                    }

                    span {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .cookies_explanation_wrapper {
        max-width: 1052px;
        padding-left: 1rem;

        h3 {
            margin-bottom: .4rem;
        }

        .c_info {
            margin-top: 1rem;

            p {
                margin-bottom: .4rem;
            }
        }
        .c_types {
            margin-top: 1rem;

            p {
                margin-bottom: .4rem;
            }
        }
    }
}

